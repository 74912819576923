export var ApiResourceName;
(function (ApiResourceName) {
    ApiResourceName["Users"] = "users";
    ApiResourceName["Claims"] = "claims";
    ApiResourceName["ClaimTypes"] = "claim_types";
    ApiResourceName["HousingTypes"] = "housing_types";
    ApiResourceName["ArtisanAppointments"] = "artisan_appointments";
    ApiResourceName["Settings"] = "settings";
    ApiResourceName["HelpContents"] = "help_contents";
    ApiResourceName["StepHelps"] = "step_helps";
    ApiResourceName["CoveringCategories"] = "covering_categories";
    ApiResourceName["ExternalSearchEnabled"] = "external/search_enabled";
    ApiResourceName["ContractsPrime"] = "contracts/prime";
    ApiResourceName["Files"] = "file";
    ApiResourceName["Countings"] = "countings";
    ApiResourceName["InsuranceContracts"] = "insurance_contracts";
    ApiResourceName["InsuranceCompanies"] = "insurance_companies";
    ApiResourceName["ProductLines"] = "product_lines";
    ApiResourceName["HousingCapacities"] = "housing_capacities";
    ApiResourceName["Departments"] = "departments";
    ApiResourceName["RoomDamages"] = "room_damages";
    ApiResourceName["PersonalProperties"] = "personal_properties";
    ApiResourceName["ExternalDamages"] = "external_damages";
    ApiResourceName["AwningTypes"] = "awning_types";
    ApiResourceName["GateTypes"] = "gate_types";
    ApiResourceName["BreakInDamages"] = "break_in_damages";
    ApiResourceName["Contracts"] = "contracts";
    ApiResourceName["PersonalPropertyCategories"] = "personal_property_categories";
    ApiResourceName["Equipments"] = "equipment";
    ApiResourceName["DamagesValuations"] = "damages_valuations";
    ApiResourceName["MessageTemplates"] = "message_templates";
    ApiResourceName["TimeLineItems"] = "time_line_items";
})(ApiResourceName || (ApiResourceName = {}));
