import { createDamageStore } from '@declaration/store/createDamageStore';
import { Step } from '@shared/claim/steps';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import externalDamageConstraints from '@shared/validator/constraints/externalDamageConstraints';
import { ExternalDamageSlug, } from '@shared/types/api/externalDamage';
import { getValidAccessToken } from '@declaration/services/accessTokenValidation';
import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
import { getExternalDamageSlugChoices } from '@shared/constants/externalDamages';
export const name = 'externalDamage';
export const namespaced = true;
function newExternalDamage() {
    return {
        id: null,
        clientIdentifier: newClientIdentifier(),
        localizationSlug: null,
        slug: null,
        pictureCollection: emptyUploadedFileCollection(),
        exclusionReason: null,
    };
}
const { createDamageState, damageGetters, damageActions, damageMutations } = createDamageStore(newExternalDamage, externalDamageConstraints, Step.ExternalDamagesDetails, 'externalDamages');
export const state = () => {
    return { ...createDamageState(), awningTypes: null, gateTypes: null };
};
export const getters = {
    ...damageGetters,
    getExternalDamagePositionSuffix: (state) => (slug, index) => {
        if (!slug) {
            return null;
        }
        let positionWithSameCategory = 1;
        let isTheOnlyOneWithCategory = true;
        state.damages.forEach((damage, otherIndex) => {
            if (damage.slug === slug && otherIndex !== index) {
                isTheOnlyOneWithCategory = false;
                if (otherIndex < index) {
                    positionWithSameCategory += 1;
                }
            }
        });
        return isTheOnlyOneWithCategory ? null : positionWithSameCategory.toString();
    },
};
export const actions = {
    ...damageActions,
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updateLocalization({ rootState, dispatch }, { localizationSlug, changeKey }) {
        const { qualification, typeTrigram } = rootState.qualification;
        if (!qualification ||
            ![ClaimTypeTrigram.StormSnowHail, ClaimTypeTrigram.Fire].includes(typeTrigram)) {
            return;
        }
        const data = { localizationSlug };
        const key = 'causeType' in qualification ? qualification.causeType : ClaimTypeTrigram.Fire;
        const slugs = key && localizationSlug && getExternalDamageSlugChoices(key, localizationSlug);
        if ((slugs === null || slugs === void 0 ? void 0 : slugs.length) === 1)
            data.slug = slugs[0].value;
        else
            data.slug = null;
        const updatePayload = { changeKey, data };
        dispatch('updateDamage', updatePayload);
    },
    updateSlug({ dispatch }, { slug, changeKey }) {
        const data = { slug };
        switch (data.slug) {
            case ExternalDamageSlug.GarageDoor:
                data.isOpeningMotorized = false;
                data.isWidthGreaterThanStandard = false;
                break;
            case ExternalDamageSlug.Awning:
                data.isOpeningMotorized = false;
                break;
        }
        const updatePayload = { changeKey, data };
        dispatch('updateDamage', updatePayload);
    },
    async validateExternalDamagesStep({ dispatch, rootState, commit }) {
        // ensure Step.ExternalDamagesDetails is in StepStack
        if (!rootState.claim.claim.stepStack.includes(Step.ExternalDamagesDetails)) {
            try {
                await dispatch('claim/saveStepWithoutStoreUpdate', Step.ExternalDamagesDetails, {
                    root: true,
                });
            }
            catch (_a) {
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
                return;
            }
        }
        await dispatch('claim/updateAndSaveClaimStep', Step.ExternalDamages, { root: true });
        commit('RESET_CHANGES');
    },
    async fetchTypes({ commit, state }) {
        const accessToken = await getValidAccessToken(this.$axios, this);
        if (accessToken === null) {
            return;
        }
        await Promise.all([
            state.awningTypes === null
                ? this.$axios
                    .$get(apiResourcePath(ApiResourceName.AwningTypes))
                    .then((awningTypes) => commit('SET_AWNING_TYPES', awningTypes['hydra:member']))
                : Promise.resolve(),
            state.gateTypes === null
                ? this.$axios
                    .$get(apiResourcePath(ApiResourceName.GateTypes))
                    .then((gateTypes) => commit('SET_GATE_TYPES', gateTypes['hydra:member']))
                : Promise.resolve(),
        ]);
    },
};
export const mutations = {
    ...damageMutations,
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    SET_AWNING_TYPES(state, awningTypes) {
        state.awningTypes = awningTypes;
    },
    SET_GATE_TYPES(state, gateTypes) {
        state.gateTypes = gateTypes;
    },
};
