import { getValidAccessToken } from '@declaration/services/accessTokenValidation';
import { ApiResourceName } from '@shared/types/api/api';
import { addDomainOnImagesSrc, } from '@shared/types/api/helpers';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { stringIdFromIri } from '@shared/utils/iri';
export const name = 'helpers';
export const namespaced = true;
export const state = () => ({
    shownStep: null,
    stepHelps: null,
});
export const getters = {};
export const actions = {
    async fetch({ commit, state }) {
        if (state.stepHelps) {
            return;
        }
        const accessToken = await getValidAccessToken(this.$axios, this);
        if (accessToken === null) {
            return;
        }
        const helpContentsRequest = this.$axios.$get(apiResourcePath(ApiResourceName.HelpContents));
        const stepHelpsRequest = this.$axios.$get(apiResourcePath(ApiResourceName.StepHelps));
        await Promise.all([helpContentsRequest, stepHelpsRequest]).then(([helpContents, stepHelps]) => {
            commit('SET_HELP', {
                helpContents: helpContents['hydra:member'],
                stepHelps: stepHelps['hydra:member'],
            });
        });
    },
    show({ commit }, step) {
        commit('SHOW', step);
    },
    hide({ commit }) {
        commit('SHOW', undefined);
    },
};
export const mutations = {
    SHOW(state, step) {
        state.shownStep = step !== null && step !== void 0 ? step : null;
    },
    SET_HELP(state, { helpContents, stepHelps }) {
        helpContents = helpContents.map((helpContent) => addDomainOnImagesSrc(helpContent));
        const help = helpContents.reduce((acc, helpContent) => ({
            ...acc,
            [helpContent.slug]: helpContent,
        }), {});
        const groupedStepHelps = stepHelps.reduce((acc, stepHelp) => {
            if (stepHelp.step in acc) {
                acc[stepHelp.step].push(stepHelp);
                return acc;
            }
            return {
                ...acc,
                [stepHelp.step]: [stepHelp],
            };
        }, {});
        for (const slug in groupedStepHelps) {
            groupedStepHelps[slug] = groupedStepHelps[slug]
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((x) => help[stringIdFromIri(x.help)]);
        }
        state.stepHelps = groupedStepHelps;
    },
};
