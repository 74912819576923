import merge from 'lodash/merge';
import { ModelFieldInputType, } from '@shared/modelField/modelField';
import { EMPTY } from '@shared/utils/strings';
import { optionalResourceIri } from '@shared/utils/iri';
import { booleanTransform, optionalBooleanTransform } from '@shared/utils/transform';
import { formatDateTime, valueToDate } from '@shared/utils/date';
import { isNotNull } from '@shared/utils/isNotNull';
export const OPTIONAL_ALLOWANCE_DATA_ITEM_NAMES_BY_CONTEXT_NAME = {
    surfaceBA13: 'surfacePercentage',
    optionRepriseSupport: 'surfacePercentage',
    roomPaintUniformity: 'wallsNumber',
    optionBarreSeuil: 'barsNumber',
};
export const optionalAllowanceToModelField = (option, model, context, column = false) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    if (!option.configuration)
        return null;
    const config = option.configuration;
    const selectedOptions = [
        ...((_a = model.optionalAllowances) !== null && _a !== void 0 ? _a : []),
    ].map((option) => ({
        ...option,
        allowance: optionalResourceIri(option.allowance),
    }));
    const currentOptionIndex = selectedOptions.findIndex((item) => item.allowance === option.id);
    const currentOption = (_b = selectedOptions[currentOptionIndex]) !== null && _b !== void 0 ? _b : null;
    const currentValue = (_f = (_e = (_d = (_c = currentOption === null || currentOption === void 0 ? void 0 : currentOption.data) === null || _c === void 0 ? void 0 : _c.items) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : null;
    const field = {
        label: config.label,
        description: config.description,
        value: null,
        displayedValue: EMPTY,
        editable: {
            input: { type: ModelFieldInputType.Checkbox },
            modelKey: 'optionalAllowances',
            toPayload: (newValue) => {
                if (newValue) {
                    if (currentOption) {
                        const updatedOption = merge({}, currentOption, {
                            data: config.type === 'default'
                                ? null
                                : {
                                    items: [
                                        {
                                            name: OPTIONAL_ALLOWANCE_DATA_ITEM_NAMES_BY_CONTEXT_NAME[config.name],
                                            value: newValue,
                                        },
                                    ],
                                },
                        });
                        selectedOptions.splice(currentOptionIndex, 1, updatedOption);
                    }
                    else {
                        selectedOptions.push({
                            allowance: option.id,
                            contextName: 'room',
                            presentationLabel: option.internalLabel,
                            data: config.type === 'default'
                                ? null
                                : {
                                    method: config.name,
                                    items: [
                                        {
                                            name: OPTIONAL_ALLOWANCE_DATA_ITEM_NAMES_BY_CONTEXT_NAME[config.name],
                                            value: newValue,
                                        },
                                    ],
                                },
                        });
                    }
                }
                else if (currentOptionIndex > -1) {
                    selectedOptions.splice(currentOptionIndex, 1);
                }
                return { optionalAllowances: selectedOptions };
            },
        },
        layout: {
            column: column ? 1 : 0,
        },
    };
    switch (config.type) {
        case 'textfield':
        case 'textarea':
            field.editable.input = { type: ModelFieldInputType.Text };
            field.value = currentValue;
            if (typeof currentValue === 'string') {
                field.displayedValue = currentValue;
            }
            break;
        case 'number':
            field.editable.input = {
                type: ModelFieldInputType.Number,
                min: (_g = config.min) !== null && _g !== void 0 ? _g : undefined,
                max: (_h = config.max) !== null && _h !== void 0 ? _h : undefined,
            };
            field.value = currentValue;
            if (typeof currentValue === 'number') {
                field.displayedValue = currentValue.toString();
            }
            break;
        case 'checkbox':
            field.value = currentValue;
            if (typeof currentValue === 'boolean') {
                field.displayedValue = booleanTransform(currentValue, context.translate);
            }
            break;
        case 'radio':
            field.editable.input = {
                type: ModelFieldInputType.Radio,
                choices: [{ value: null, label: 'Non' }, ...config.values],
            };
            field.value = currentValue;
            if (currentValue !== null) {
                field.displayedValue =
                    (_k = (_j = config.values.find((val) => val.value === currentValue)) === null || _j === void 0 ? void 0 : _j.label) !== null && _k !== void 0 ? _k : currentValue.toString();
            }
            break;
        case 'select':
            field.editable.input = {
                type: ModelFieldInputType.Select,
                choices: [{ value: null, label: 'Non' }, ...config.values],
            };
            field.value = currentValue;
            if (currentValue !== null) {
                field.displayedValue =
                    (_m = (_l = config.values.find((val) => val.value === currentValue)) === null || _l === void 0 ? void 0 : _l.label) !== null && _m !== void 0 ? _m : currentValue.toString();
            }
            break;
        case 'checklist':
            field.editable.input = {
                type: ModelFieldInputType.MultipleChoice,
                choices: config.values,
            };
            field.value = currentValue;
            if (currentValue !== null) {
                field.displayedValue = config.values
                    .filter((val) => currentValue.includes(val.value))
                    .map(({ label, value }) => label !== null && label !== void 0 ? label : value.toString())
                    .join(', ');
            }
            break;
        case 'datetime':
            field.editable.input = { type: ModelFieldInputType.DateTime };
            if (typeof currentValue === 'string') {
                const date = valueToDate(currentValue);
                field.value = date;
                if (!isNaN(date.getTime()))
                    field.displayedValue = formatDateTime(currentValue);
            }
            break;
        default:
            field.editable.input = {
                type: ModelFieldInputType.Radio,
                choices: [
                    { value: false, label: 'Non' },
                    { value: true, label: 'Oui' },
                ],
            };
            field.value = currentOption !== null;
            field.displayedValue = optionalBooleanTransform(currentOption !== null, context.translate);
            break;
    }
    return field;
};
export const optionalAllowancesToModelFields = (options, model, context, column = false) => {
    var _a;
    const fields = [];
    if (context.edition) {
        if (options.length > 0) {
            fields.push({
                separator: 'Options',
                layout: {
                    column: column ? 1 : 0,
                },
            });
        }
        options.forEach((option) => {
            const field = optionalAllowanceToModelField(option, model, context, column);
            if (field)
                fields.push(field);
        });
    }
    else {
        const optionalAllowances = ((_a = model.optionalAllowances) !== null && _a !== void 0 ? _a : []);
        const value = optionalAllowances
            .map((optionalAllowance) => {
            var _a, _b;
            const option = options.find((option) => {
                return option.id === optionalResourceIri(optionalAllowance.allowance);
            });
            return (_b = (_a = option === null || option === void 0 ? void 0 : option.configuration) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : null;
        })
            .filter(isNotNull);
        fields.push({
            label: 'Options',
            displayedValue: value,
            key: 'optionalAllowances',
        });
    }
    return fields;
};
