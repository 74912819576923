import { tagCommanderReload, tagCommanderSetVariables } from '@declaration/helpers/tagCommander';
import { CONFIG } from '@shared/environment/environment';
import { INSURER_CONFIG } from '@shared/insurer';
function routerMiddleware(context) {
    var _a;
    const allowLogin = INSURER_CONFIG.declaration.allowLogin.includes(CONFIG.environment);
    if (context.route.path.startsWith('/login') && !allowLogin) {
        return context.redirect('/access-forbidden');
    }
    if (((_a = context.route.meta) === null || _a === void 0 ? void 0 : _a.length) && context.route.meta[0].tcVars) {
        tagCommanderSetVariables(context.route.meta[0].tcVars);
        tagCommanderReload();
    }
}
export default routerMiddleware;
