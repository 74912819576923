import { createDamageStore } from '@declaration/store/createDamageStore';
import { Step } from '@shared/claim/steps';
import { stringContainsCaseInsensitive } from '@declaration/utils/string';
import { INITIAL_PERSONAL_PROPERTY_CATEGORIES } from '@shared/constants/personalPropertyConstants';
import { emptyUploadedFileCollection } from '@shared/files/uploadedFiles';
import { ApiResourceName } from '@shared/types/api/api';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
import { PersonalPropertyInitialCategorySlug, PersonalPropertyType, } from '@shared/types/api/personalProperty';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
import { indexBy } from '@shared/utils/indexBy';
import personalPropertyConstraints from '@shared/validator/constraints/personalPropertyConstraints';
import { newClientIdentifier } from '@shared/utils/clientIdentifier';
import { optionalResourceIri, resourceIri } from '@shared/utils/iri';
import { initializePersonalProperty } from '@shared/personalProperty/personalPropertyHelpers';
import { ClaimTypeFeatureFlag, claimTypeHasFeature } from '@shared/claim/claimTypeFeatureFlags';
export const name = 'personalProperty';
export const namespaced = true;
function newPersonalProperty() {
    return {
        id: null,
        type: PersonalPropertyType.Default,
        clientIdentifier: newClientIdentifier(),
        age: null,
        amount: null,
        thirdPartyProductId: null,
        thirdPartyProductInfo: null,
        initialCategorySlug: null,
        category: null,
        description: null,
        designation: null,
        isUnderWarranty: null,
        isWaitingForRepairFile: false,
        isRepairQuoteMandatory: null,
        repairQuoteInfo: null,
        repairFileCollection: emptyUploadedFileCollection(),
        receipt: null,
        repairableStatus: null,
        exclusionReason: null,
        pictureCollection: emptyUploadedFileCollection(),
        documentType: null,
        userKnowPrice: null,
        userKnowPurchaseDate: null,
        windowCount: null,
        widestWindowWidth: null,
        widestWindowHeight: null,
    };
}
const { createDamageState, damageGetters, damageActions, damageMutations } = createDamageStore(newPersonalProperty, personalPropertyConstraints, Step.PersonalPropertiesDetails, 'personalProperties', { step: Step.NumberOfPersonalPropertyDamages, categoryKey: 'initialCategorySlug' }, (initialCategorySlug, state, _getters, rootState) => {
    var _a, _b, _c, _d, _e, _f, _g;
    let category;
    let personalProperty;
    switch (rootState.qualification.typeTrigram) {
        case ClaimTypeTrigram.Glass:
            personalProperty = initializePersonalProperty(PersonalPropertyType.GlassBreakage);
            category = (_b = (_a = state.categories) === null || _a === void 0 ? void 0 : _a.find((category) => category.data.slug === 'autres')) === null || _b === void 0 ? void 0 : _b.data.id;
            break;
        case ClaimTypeTrigram.Fire:
            personalProperty = initializePersonalProperty(PersonalPropertyType.Default);
            category = (_e = (((_c = state.categories) === null || _c === void 0 ? void 0 : _c.find((category) => category.data.slug === initialCategorySlug)) ||
                ((_d = state.categories) === null || _d === void 0 ? void 0 : _d.find((category) => category.data.slug === 'autres')))) === null || _e === void 0 ? void 0 : _e.data.id;
            break;
        default:
            personalProperty = initializePersonalProperty(PersonalPropertyType.Default);
            category =
                initialCategorySlug === PersonalPropertyInitialCategorySlug.Autres
                    ? undefined
                    : (_g = (_f = state.categories) === null || _f === void 0 ? void 0 : _f.find((category) => category.data.slug === initialCategorySlug)) === null || _g === void 0 ? void 0 : _g.data.id;
            break;
    }
    return { ...personalProperty, category, initialCategorySlug };
});
const DEFAULT_DESIGNATIONS = ['Lave-vaisselle', 'Télévision', 'Grille-Pain', 'Yaourtière'].sort();
export const state = () => {
    return {
        ...createDamageState(),
        categories: null,
        categoriesByIri: {},
    };
};
export const getters = {
    ...damageGetters,
    filteredDesignations(_state, getters) {
        return (changeKey) => {
            const change = getters.change(changeKey);
            return DEFAULT_DESIGNATIONS.filter((designation) => change.data.designation
                ? stringContainsCaseInsensitive(change.data.designation, designation)
                : true).sort();
        };
    },
    getCategorySubLabel: (state) => (categoryIri, indexInDamages) => {
        if (!state.categories || !categoryIri) {
            return null;
        }
        const category = state.categoriesByIri[categoryIri];
        if (!category) {
            return null;
        }
        let positionWithSameCategory = 1;
        let isTheOnlyOneWithCategory = true;
        state.damages.forEach((damage, otherIndex) => {
            if (optionalResourceIri(damage.category) === resourceIri(categoryIri) &&
                otherIndex !== indexInDamages) {
                isTheOnlyOneWithCategory = false;
                if (otherIndex < indexInDamages) {
                    positionWithSameCategory += 1;
                }
            }
        });
        if (isTheOnlyOneWithCategory) {
            return category.label;
        }
        return `${category.label} ${positionWithSameCategory}`;
    },
    isCategoryModifiable: (_, getters, rootState) => (changeKey) => {
        const personalProperty = getters.change(changeKey).data;
        if (personalProperty.initialCategorySlug === PersonalPropertyInitialCategorySlug.Autres &&
            claimTypeHasFeature(rootState.qualification.typeTrigram, ClaimTypeFeatureFlag.PersonalPropertyDescribeOther))
            return false;
        return (!personalProperty.initialCategorySlug ||
            !personalProperty.category ||
            INITIAL_PERSONAL_PROPERTY_CATEGORIES[personalProperty.initialCategorySlug]
                .isCategoryModifiable);
    },
    isAmountOnly: (state, getters) => (entity) => {
        var _a;
        const personalProperty = entity === null || typeof entity === 'string' || typeof entity === 'number'
            ? getters.change(entity).data
            : entity;
        const currentCategoryIri = optionalResourceIri(personalProperty.category);
        if (!currentCategoryIri) {
            return false;
        }
        const category = (_a = state.categories) === null || _a === void 0 ? void 0 : _a.find((category) => category.data.id === currentCategoryIri);
        return !!category && category.data.isAmountOnly;
    },
    isIdentityDocument: (state, getters) => (entity) => {
        var _a;
        const personalProperty = entity === null || typeof entity === 'string' || typeof entity === 'number'
            ? getters.change(entity).data
            : entity;
        const currentCategoryIri = optionalResourceIri(personalProperty.category);
        if (!currentCategoryIri) {
            return false;
        }
        const category = (_a = state.categories) === null || _a === void 0 ? void 0 : _a.find((category) => category.data.id === currentCategoryIri);
        return !!category && category.data.isIdentityDocument;
    },
    getCategoryPositionSuffix: (state) => (damage, indexInDamages) => {
        if (!damage.initialCategorySlug) {
            let positionWithSameCategory = 1;
            let isTheOnlyOneWithCategory = true;
            state.damages.forEach((otherDamage, otherIndex) => {
                if (otherDamage.category === damage.category && otherIndex !== indexInDamages) {
                    isTheOnlyOneWithCategory = false;
                    if (otherIndex < indexInDamages) {
                        positionWithSameCategory += 1;
                    }
                }
            });
            return isTheOnlyOneWithCategory ? null : positionWithSameCategory.toString();
        }
        let positionWithSameCategory = 1;
        let isTheOnlyOneWithCategory = true;
        state.damages.forEach((otherDamage, otherIndex) => {
            if (otherDamage.initialCategorySlug === damage.initialCategorySlug &&
                otherIndex !== indexInDamages) {
                isTheOnlyOneWithCategory = false;
                if (otherIndex < indexInDamages) {
                    positionWithSameCategory += 1;
                }
            }
        });
        return isTheOnlyOneWithCategory ? null : positionWithSameCategory.toString();
    },
};
export const actions = {
    ...damageActions,
    resetState({ commit }) {
        commit('RESET_STATE');
    },
    updateStoreWithClaimResponse({ commit }, claimResponse) {
        commit('SET_DAMAGES', claimResponse.counting.personalProperties);
    },
    async fetchCategories({ state, commit, rootState }) {
        if (state.categories || !rootState.claim.claim.id) {
            return;
        }
        const response = await this.$axios.$get(apiResourcePath(ApiResourceName.PersonalPropertyCategories));
        commit('SET_CATEGORIES', response['hydra:member']);
    },
    async validatePersonalPropertiesStep({ dispatch, rootState, commit }) {
        // ensure Step.PersonalPropertiesDetails is in StepStack
        if (!rootState.claim.claim.stepStack.includes(Step.PersonalPropertiesDetails)) {
            try {
                await dispatch('claim/saveStepWithoutStoreUpdate', Step.PersonalPropertiesDetails, {
                    root: true,
                });
            }
            catch (_a) {
                dispatch('error/add', 'Une erreur est survenue, merci de réessayer.', { root: true });
                return;
            }
        }
        await dispatch('claim/updateAndSaveClaimStep', Step.PersonalProperties, { root: true });
        commit('RESET_CHANGES');
    },
};
export const mutations = {
    ...damageMutations,
    RESET_STATE(stateObject) {
        Object.assign(stateObject, state());
    },
    SET_CATEGORIES(state, categories) {
        // Sub-categorization using a 2-levels structure
        const rankedCategories = [];
        // We store categories following 2 steps because as they are received we don't know in which
        // order they are stored, so doing a single loop could lead to referencing problems (e.g. trying
        // to store a subcategory in a non-yet-stored category)
        // Step 1 : store parent categories on the first level
        for (const category of categories) {
            if (category.subcategoryOf === null) {
                rankedCategories.push({ data: category, subcategories: [] });
            }
        }
        // Step 2 : store each subcategory in its parent subcategories array
        for (const category of categories) {
            if (category.subcategoryOf !== null) {
                const parentIndex = rankedCategories.findIndex((c) => c.data.slug === category.subcategoryOf);
                if (parentIndex !== -1) {
                    rankedCategories[parentIndex].subcategories.push(category);
                }
            }
        }
        // Step 3 : sort all arrays
        for (const category of rankedCategories) {
            if (category.subcategories.length !== 0) {
                category.subcategories = category.subcategories.sort((a, b) => a.label.localeCompare(b.label));
            }
        }
        state.categories = rankedCategories
            .sort((a, b) => a.data.label.localeCompare(b.data.label))
            .sort((a, b) => {
            if (a.data.isFallback && !b.data.isFallback)
                return 1;
            if (b.data.isFallback && !a.data.isFallback)
                return -1;
            return 0;
        });
        state.categoriesByIri = indexBy(categories, 'id');
    },
};
